var $class="se2--content-fact-highlights",$name="ContentFactHighlights",$path="app/components/ContentFactHighlights/index.js",$this={$class,$name,$path,};import lazyHandleOpenModalButtons from 'app/components/utilities/lazyHandleOpenModalButtons';

import 'app/modules/accessibility';
import shell from 'app/modules/shell';

import 'app/partials/image';

export default shell.registerComponent($this, ({ addEventListener, mount }) => {
  mount((element) => {
    lazyHandleOpenModalButtons(addEventListener, element);
  });
});
